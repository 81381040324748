import React from "react"
import "./InvoicePdf.less"
import { Document, Font, Page, Text, View } from "@react-pdf/renderer"
import { formatDate, formatPrice } from "../utils"
import compose from "./InvoicePdf.styles"
import { useTranslation } from "react-i18next"

Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
})

const InvoicePdf = ({ data }) => {
  const {
    created_at,
    currency,
    description,
    item_id,
    item_type,
    price,
    final_price,
    state,
    user,
  } = data

  const { t } = useTranslation()

  const priceWithoutVat = final_price / 1.22
  const vat = final_price - priceWithoutVat

  const {
    billing_address,
    address,
    firstname,
    lastname,
    tax_name,
    vat_number,
  } = user.billing_profile || user.basic_profile || {}

  const { city, postal_code, address_line1, address_line2 } =
    billing_address || address || {}

  return (
    <Document>
      <Page size="A4" style={compose("page")}>
        <View style={compose("view flex")}>
          <View style={compose("view w-50")}>
            <Text style={compose("span fs-20 bold")}>
              {t("invoice:platform")}
            </Text>
            <Text style={compose("span")}>
              {t("invoice:supplierBusinessName")}
            </Text>
            <Text style={compose("span")}>{t("invoice:supplierAddress")}</Text>
            <Text style={compose("span")}>{t("invoice:supplierLocation")}</Text>
            <Text style={compose("span")}>
              {t("invoice:supplierVatNumber")}
            </Text>
          </View>
          <View style={compose("view w-50")}>
            <Text style={compose("span fs-45 right bold")}>
              {t("invoice:receipt")}
            </Text>
          </View>
        </View>
        <View style={compose("view flex mt-40")}>
          <View style={compose("view w-55")}>
            <Text style={compose("span bold dark mb-5")}>
              {t("invoice:customer")}:
            </Text>
            <Text style={compose("span")}>
              {tax_name || `${firstname ?? ""} ${lastname ?? ""}`}
            </Text>
            {(address || billing_address) && (
              <>
                <Text style={compose("span")}>{address_line1 ?? ""}</Text>
                <Text style={compose("span")}>{address_line2 ?? ""}</Text>
                <Text style={compose("span")}>
                  {postal_code ?? ""}, {city ?? ""}
                </Text>
              </>
            )}
          </View>

          <View style={compose("view w-45")}>
            <View style={compose("view flex mb-5")}>
              <View style={compose("view flex")}>
                <Text style={compose("span bold")}>
                  {t("invoice:purchasedAt")}
                </Text>
              </View>
            </View>
            <View style={compose("view flex mb-5")}>
              <View style={compose("view flex")}>
                <Text style={compose("span bold")}>
                  {formatDate(created_at, { format: t("generic:dateFormat") })}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={compose("view mt-30 bg-dark flex")}>
          <View style={compose("view w-48 p-4-8")}>
            <Text style={compose("span white bold")}>
              {t("invoice:product")}
            </Text>
          </View>
          <View style={compose("view w-17 p-4-8")}>
            <Text style={compose("span white bold right")}></Text>
          </View>
          <View style={compose("view w-17 p-4-8")}>
            <Text style={compose("span white bold right")}>
              {t("invoice:quantity")}
            </Text>
          </View>
          <View style={compose("view w-18 p-4-8")}>
            <Text style={compose("span white bold right")}>
              {t("invoice:amount")}
            </Text>
          </View>
        </View>

        <View style={compose("view row flex")}>
          <View style={compose("view w-48 p-4-8 pb-10")}>
            <Text style={compose("span dark")}>{description}</Text>
          </View>
          <View style={compose("view w-17 p-4-8 pb-10")}>
            <Text style={compose("span dark right")}></Text>
          </View>
          <View style={compose("view w-17 p-4-8 pb-10")}>
            <Text style={compose("span dark right")}>1</Text>
          </View>
          <View style={compose("view w-18 p-4-8 pb-10")}>
            <Text style={compose("span dark right")}>
              {formatPrice(priceWithoutVat)}
            </Text>
          </View>
        </View>

        <View style={compose("view flex")}>
          <View style={compose("view w-50 mt-10")}></View>
          <View style={compose("view w-50 mt-20")}>
            <View style={compose("view flex")}>
              <View style={compose("view w-50 p-5")}>
                <Text style={compose("span")}>{t("invoice:amount")}</Text>
              </View>
              <View style={compose("view w-50 p-5")}>
                <Text style={compose("span right bold dark")}>
                  {formatPrice(priceWithoutVat)}
                </Text>
              </View>
            </View>
            <View style={compose("view flex")}>
              <View style={compose("view w-50 p-5")}>
                <Text style={compose("span")}>{t("invoice:vat")}</Text>
              </View>
              <View style={compose("view w-50 p-5")}>
                <Text style={compose("span right bold dark")}>
                  {formatPrice(vat)}
                </Text>
              </View>
            </View>

            <View style={compose("view flex bg-gray p-5")}>
              <View style={compose("view w-50 p-5")}>
                <Text style={compose("span bold")}>{t("invoice:total")}</Text>
              </View>
              <View style={compose("view w-50 p-5 flex")}>
                <Text style={compose("span right bold dark w-auto")}>
                  {formatPrice(final_price)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default InvoicePdf
