const colorDark = "#222"
const colorDark2 = "#666"
const colorGray = "#e3e3e3"
const colorWhite = "#fff"

const styles = {
  dark: {
    color: colorDark,
  },

  white: {
    color: colorWhite,
  },

  "bg-dark": {
    backgroundColor: colorDark2,
  },

  "bg-gray": {
    backgroundColor: colorGray,
  },

  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  "w-auto": {
    flex: 1,
    paddingRight: "8pt",
  },

  "ml-30": {
    flex: 1,
  },

  "w-100": {
    width: "100%",
  },

  "w-50": {
    width: "50%",
  },

  "w-55": {
    width: "55%",
  },

  "w-45": {
    width: "45%",
  },

  "w-60": {
    width: "60%",
  },

  "w-40": {
    width: "40%",
  },

  "w-48": {
    width: "48%",
  },

  "w-17": {
    width: "17%",
  },

  "w-18": {
    width: "18%",
  },

  row: {
    borderBottom: `1pt solid ${colorGray}`,
  },

  "mt-40": {
    marginTop: "40pt",
  },

  "mt-30": {
    marginTop: "30pt",
  },

  "mt-20": {
    marginTop: "20pt",
  },

  "mt-10": {
    marginTop: "10pt",
  },

  "mb-5": {
    marginBottom: "5pt",
  },

  "p-4-8": {
    padding: "4pt 8pt",
  },

  "p-5": {
    padding: "5pt",
  },

  "pb-10": {
    paddingBottom: "10pt",
  },

  right: {
    textAlign: "right",
  },

  bold: {
    fontWeight: "bold",
  },

  "fs-20": {
    fontSize: "20pt",
  },

  "fs-45": {
    fontSize: "45pt",
  },

  page: {
    fontFamily: "Nunito",
    fontSize: "13pt",
    color: "#555",
    padding: "40pt 35pt",
  },

  span: {
    padding: "4pt 12pt 4pt 0",
  },
}

const compose = classes => {
  const css = []
  const classesArray = classes.replace(/\s+/g, " ").split(" ")
  classesArray.forEach(className => {
    if (typeof styles[className] !== undefined) {
      css.push(styles[className])
    }
  })

  return css
}

export default compose
