import React, { useMemo } from "react"
import QueryString from "query-string"
import InvoicePdf from "../components/InvoicePdf"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "../components/LoadingScreen"
import ErrorScreen from "../components/ErrorScreen"
import { PDFViewer } from "@react-pdf/renderer"

const GET_USER_PURCHASE = gql`
  query getPurchase($id: uuid!) {
    user_purchases_by_pk(id: $id) {
      created_at
      currency
      description
      item_id
      item_type
      price
      final_price
      state
      user {
        basic_profile {
          firstname
          lastname
        }
        billing_profile {
          billing_address
          firstname
          lastname
          tax_name
          vat_number
        }
      }
    }
  }
`

const InvoicePage = ({ location }) => {
  const { purchaseId } = QueryString.parse(location.search)
  const { loading, error, data } = useQuery(GET_USER_PURCHASE, {
    variables: { id: purchaseId },
    fetchPolicy: "network-only",
  })

  const pdf = useMemo(() => {
    if (data?.user_purchases_by_pk) {
      return <InvoicePdf data={data.user_purchases_by_pk} />
    } else {
      return null
    }
  }, [data])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { created_at } = data?.user_purchases_by_pk ?? {}

  return (
    <PDFViewer style={{ height: "100vh", width: "100vw" }}>{pdf}</PDFViewer>
  )
}

export default InvoicePage
